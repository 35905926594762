<template>
  <v-container
    v-if="submitForm"
    class="py-0"
  >
    <v-col cols="12">
      <validation-observer
        ref="obs"
        v-slot="{ handleSubmit }"
      >
        <base-material-card
          inline
          icon="mdi-account-outline"
          :title="$t('client.Client_Information')"
          class="px-5 py-3"
        >
          <form>
            <v-subheader class="display-1 mt-3">
              Information
            </v-subheader>
            <div
              v-if="!editForm"
              class="text-right"
            >
              <v-btn
                color="success"
                @click="editForm = true"
              >
                {{ $t('Edit') }}
              </v-btn>
            </div>

            <!--  Username-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('client.Username') }}</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('client.Username') }}:</span>
              </v-col>

              <v-col cols="8">
                <!--  Editable-->
                <v-text-field
                  v-if="editForm"
                  v-model="submitForm.username"
                  disabled
                />
                <!--  Non Editable-->
                <span v-else>{{ submitForm.username }}</span>
              </v-col>
            </v-row>

            <!--  Sexe-->
            <!-- <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <span v-if="editForm">{{ $t('client.Sex') }}*</span>
                <span v-else>{{ $t('client.Sex') }}:</span>
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.Sex')"
                  rules="required"
                >
                  <v-select
                    v-if="editForm"
                    v-model="submitForm.sexe"
                    :items="sexes"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                  <span v-else>{{ submitForm.sexe }}</span>
                </validation-provider>
              </v-col>
            </v-row> -->

            <!--  Nom-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('Name') }}</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('Name') }}:</span>
              </v-col>

              <v-col cols="8">
                <!--  Editable-->
                <v-text-field
                  v-if="editForm"
                  v-model="submitForm.nom"
                  disabled
                />
                <!--  Non Editable-->
                <span v-else>{{ submitForm.nom }}</span>
              </v-col>
            </v-row>

            <!--  Prénom-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('FirstName') }}</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('FirstName') }}:</span>
              </v-col>

              <v-col cols="8">
                <!--  Editable-->
                <v-text-field
                  v-if="editForm"
                  v-model="submitForm.prenom"
                  disabled
                />
                <!--  Non Editable-->
                <span v-else>{{ submitForm.prenom }}</span>
              </v-col>
            </v-row>

            <!--  Téléphone principal-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('client.Primary_phone') }}</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('client.Primary_phone') }}:</span>
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.Primary_phone')"
                  :rules="{ required: false, regex: regexPhoneNumber }"
                >
                  <!--  Editable-->
                  <v-text-field
                    v-if="editForm"
                    v-model="submitForm.telephone1"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                  <!--  Non Editable-->
                  <span v-else>{{ submitForm.telephone1 | phoneNumberFormat }}</span>
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Poste-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('profile.Extension') }}</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('profile.Extension') }}:</span>
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('profile.Extension')"
                  :rules="{ required: false, numeric:true }"
                >
                  <!--  Editable-->
                  <v-text-field
                    v-if="editForm"
                    v-model="submitForm.telephone2"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                  <!--  Non Editable-->
                  <span v-else>{{ submitForm.telephone2 }}</span>
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Courriel-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('client.Email') }}*</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('client.Email') }}:</span>
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.Email')"
                  rules="required|email"
                >
                  <!--  Editable-->
                  <v-text-field
                    v-if="editForm"
                    v-model="submitForm.email"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                  <!--  Non Editable-->
                  <span v-else>{{ submitForm.email }}</span>
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Langue-->
            <v-row
              v-if="$company !== 'uqam'"
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('client.Language') }}*</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('client.Language') }}:</span>
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.Language')"
                  :rules="{ required: true }"
                >
                  <!--  Editable-->
                  <v-select
                    v-if="editForm"
                    v-model="submitForm.langue"
                    :placeholder="$t('profile.Choose_language')"
                    :items="language"
                    item-text="item"
                    item-value="valeur"
                    persistent-hint
                    single-line
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                  <!--  Non Editable-->
                  <span v-else>{{ getLanguage }}</span>
                </validation-provider>
              </v-col>
            </v-row>

            <v-subheader class="display-1 mt-3">
              {{ $t('client.Address') }}
            </v-subheader>

            <!--  Adresse-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('client.Address') }}*</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('client.Address') }}:</span>
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.Address')"
                  rules="required"
                >
                  <!--  Editable-->
                  <v-text-field
                    v-if="editForm"
                    v-model="submitForm.adresse"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                  <!--  Non Editable-->
                  <span v-else>{{ submitForm.adresse }}</span>
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Ville-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('client.City') }}*</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('client.City') }}:</span>
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.City')"
                  rules="required"
                >
                  <!--  Editable-->
                  <v-text-field
                    v-if="editForm"
                    v-model="submitForm.ville"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                  <!--  Non Editable-->
                  <span v-else>{{ submitForm.ville }}</span>
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Province-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">Province*</span>
                <!--  Non Editable-->
                <span v-else>Province:</span>
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Province"
                  rules="required"
                >
                  <!--  Editable-->
                  <v-select
                    v-if="editForm"
                    v-model="submitForm.province"
                    :items="provinces"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                  <!--  Non Editable-->
                  <span v-else>{{ submitForm.province }}</span>
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Code Postale-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                <!--  Editable-->
                <span v-if="editForm">{{ $t('client.ZIP_code') }}*</span>
                <!--  Non Editable-->
                <span v-else>{{ $t('client.ZIP_code') }}:</span>
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.ZIP_code')"
                  :rules="{ required: true, regex: regexZipCode }"
                >
                  <!--  Editable-->
                  <v-text-field
                    v-if="editForm"
                    v-model="submitForm.code_postal"
                    :error-messages="errors"
                    :success="valid"
                    placeholder="H0H0H0"
                    required
                  />
                  <!--  Non Editable-->
                  <span v-else>{{ submitForm.code_postal }}</span>
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Mot (Champs requis)-->
            <v-col
              v-if="editForm"
              cols="2"
            >
              <div class="body-2 font-weight-light">
                *{{ $t('Required_Fields') }}
              </div>
            </v-col>

            <div
              v-if="editForm"
              class="pa-3 text-center"
            >
              <!--  Bouton annuler-->
              <v-btn
                color="error"
                @click="cancel"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <!--  Bouton submit-->
              <v-btn
                color="success"
                class="ml-3"
                @click="handleSubmit(submit)"
              >
                {{ $t('Validate') }}
              </v-btn>
            </div>
          </form>

          <!--  Paiement automatisé-->
          <template v-if="!editForm">
            <v-subheader
              class="display-1 mt-3"
            >
              {{ $t('payment.Automated_payment') }}
            </v-subheader>

            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              />

              <v-col cols="8">
                <!-- Carte-->
                <!-- On affiche si il y a une adresse-->
                <div
                  class="mt-4"
                >
                  <span
                    class="display-1 text-decoration-underline"
                  >
                    {{ $t('order.Credit_card') }}
                  </span>
                  <!-- Card-->
                  <v-row
                    dense
                  >
                    <v-col
                      v-for="card in cards"
                      :key="card.id"
                      cols="6"
                    >
                      <v-card
                        class="mx-auto"
                        max-width="420"
                        outlined
                      >
                        <v-list-item three-line>
                          <v-list-item-content>
                            <!-- Type de carte-->
                            <v-list-item-title class="text-h2 mb-1">
                              {{ cardType[card.card.brand] }}
                            </v-list-item-title>

                            <!-- HolderName-->
                            <v-list-item-title class="text-h4 mb-1">
                              {{ card.name }}
                            </v-list-item-title>

                            <!-- Dernier numéro-->
                            <v-list-item-title>{{ $t('profile.automatic_payment.Credit_card_ending') }} {{ card.card.number_last4 }}</v-list-item-title>
                            <!-- Expiration-->
                            <v-list-item-subtitle
                              class="mt-1"
                            >
                              Expiration: {{ card.card.expiry_month }}/{{ card.card.expiry_year }}
                              <v-tooltip
                                v-if="ifCardExpired({ year: card.card.expiry_year, month: card.card.expiry_month})"
                                top
                              >
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    color="yellow accent-3"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-alert
                                  </v-icon>
                                </template>
                                <span>{{ $t('profile.automatic_payment.Expired_card') }}</span>
                              </v-tooltip>
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-icon>
                            <v-icon color="green">
                              mdi-credit-card
                            </v-icon>
                          </v-list-item-icon>
                          <!-- <v-list-item-avatar
                            tile
                            size="80"
                          >
                            <v-img :src="card.src" />
                          </v-list-item-avatar> -->
                        </v-list-item>

                        <v-card-actions>
                          <v-btn
                            rounded
                            text
                            color="green"
                            @click="editCard(card)"
                          >
                            {{ $t('Edit') }}
                          </v-btn>

                          <v-btn
                            rounded
                            text
                            color="primary"
                            @click="deleteCard(card)"
                          >
                            {{ $t('Delete') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- Ajouter une carte-->
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-btn
                        small
                        class="mt-3"
                        color="success"
                        @click="createNewCard"
                      >
                        {{ $t('profile.automatic_payment.Add_card') }}
                        <v-icon
                          right
                        >
                          mdi-credit-card-plus-outline
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </template>
        </base-material-card>
      </validation-observer>
    </v-col>

    <!--  Dialog pour ajouter/modifier une carte-->
    <v-dialog
      v-model="showAddEditCardDialog"
      max-width="1200"
    >
      <v-card>
        <v-card-title>
          <v-icon
            aria-label="Close"
            @click="showAddEditCardDialog = !showAddEditCardDialog"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              class="body-1 text-center"
              cols="12"
            >
              <v-icon
                size="78px"
                color="success"
              >
                mdi-credit-card-outline
              </v-icon>

              <h2 class="mt-3">
                {{ $t('profile.automatic_payment.name') }}
              </h2>

              <div class="mt-3">
                <p v-html="cardSelected.reference ? `${$t('profile.automatic_payment.message_editCard')} ....${cardSelected.card.number_last4}` : $t('profile.automatic_payment.message_newCard')" />
              </div>
            </v-col>
          </v-row>

          <!-- Bouton inscription-->
          <validation-observer
            ref="obsNewCreditCard"
            v-slot="{ handleSubmit }"
          >
            <form>
              <!-- Si c'est une édition, le client doit cliquer sur le checkbox pour changer sa carte-->
              <v-row
                v-if="cardSelected.reference"
                justify="center"
                dense
              />

              <!-- Ajout ou modification de la carte-->
              <!-- Nom-->
              <v-row
                justify="center"
                dense
              >
                <!-- Nom sur la carte-->
                <v-col
                  cols="10"
                >
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('profile.automatic_payment.HolderName')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="cardSelected.name"
                      :label="$t('profile.automatic_payment.HolderName')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <!-- Numéro de carte-->
              <v-row
                justify="center"
                dense
              >
                <!-- Numéro de carte-->
                <v-col
                  cols="10"
                >
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('profile.automatic_payment.No_credit_card')"
                    :rules="{ required: true, min: 15, max: 16, numeric: true }"
                  >
                    <v-text-field
                      v-model="cardSelected.card.number"
                      maxlength="16"
                      :label="$t('profile.automatic_payment.No_credit_card')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <!-- Expiration-->
              <v-row
                justify="center"
                dense
              >
                <v-col cols="10">
                  <v-row
                    justify="center"
                    dense
                  >
                    <!-- Mois-->
                    <v-col
                      cols="6"
                    >
                      <validation-provider
                        v-slot="{ errors, valid }"
                        :name="$t('time.month')"
                        :rules="{ required:true, validCardMonth: cardSelected.card.expiry_year }"
                      >
                        <v-select
                          v-model="cardSelected.card.expiry_month"
                          :items="cardExpiration.month"
                          :label="$t('time.month')"
                          :error-messages="errors"
                          :success="valid"
                          required
                        />
                      </validation-provider>
                    </v-col>

                    <!-- year-->
                    <v-col cols="6">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        :name="$t('time.year')"
                        rules="required"
                      >
                        <v-select
                          v-model="cardSelected.card.expiry_year"
                          :items="cardExpiration.year"
                          :label="$t('time.year')"
                          :error-messages="errors"
                          :success="valid"
                          required
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- CVV-->
              <v-row
                justify="center"
                dense
              >
                <v-col cols="10">
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="CVV"
                    :rules="{ required: true, min: 3, max: 4, numeric: true }"
                  >
                    <v-text-field
                      v-model="cardSelected.card.cvv"
                      maxlength="4"
                      label="CVV"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row
                dense
              >
                <v-col
                  offset-md="1"
                  cols="12"
                >
                  <div class="body-2 red--text">
                    *{{ $t('profile.automatic_payment.Prepaid_card_warning') }}
                  </div>
                </v-col>

                <v-col
                  v-if="$company === 'uqam'"
                  offset-md="1"
                  cols="12"
                >
                  <div
                    class="body-2 red--text"
                  >
                    *{{ $t('profile.automatic_payment.Card_warning') }}
                  </div>
                </v-col>
              </v-row>

              <!--  Bouton submit-->
              <div class="text-center">
                <v-btn
                  color="success"
                  class="ml-3 mt-4"
                  @click="handleSubmit(submitNewCreditCard)"
                >
                  {{ $t('Validate') }}
                </v-btn>
              </div>
            </form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  // Service
  import ClientsService from '@/services/01Cell/ClientsService'
  import ContratsService from '@/services/01Cell/ContratsService'
  import PaiementsInscriptionService from '@/services/01Cell/PaiementsInscriptionService'
  import GlobalPaymentsCardService from '@/services/GLOBALPAYMENTS/GlobalPaymentsCardService'
  // Mixins
  import { ClientEdit } from '@/mixins/Client/ClientEdit'
  // Other
  import Swal from 'sweetalert2'
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'Profile',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [ClientEdit],
    data () {
      return {
        submitForm: null,
        cards: [],
        editForm: false,
        showAddEditCardDialog: false,
        language: [
          {
            item: this.$i18n.t('French'),
            valeur: 'fr',
          },
          {
            item: this.$i18n.t('English'),
            valeur: 'en',
          },
        ],
        cardSelected: {
          reference: null, // Cet variable se rmpli dans le backend. C'est ex: "uqam_'clientId'"
          usage_mode: 'MULTIPLE',
          name: null,
          card: {
            number: null,
            expiry_month: null,
            expiry_year: null,
            cvv: null,
          },
        },
        editCreateCardChange: false,
        editCardStatus: false,
        cardExpiration: {
          month: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
          year: [],
        },
        cardType: {
          VISA: 'Visa',
          AMEX: 'American Express',
          DINERS: 'Diners',
          MASTERCARD: 'MasterCard',
          DISCOVER: 'Discover',
          JCB: 'JCB',
          CUP: 'CUP',
        },
      }
    },

    computed: {
      ...mapState(['userInfo']),
      getLanguage () {
        if (this.submitForm.langue === 'fr') return this.$i18n.t('French')
        if (this.submitForm.langue === 'en') return this.$i18n.t('English')
        return this.submitForm.langue
      },
    },

    mounted () {
      // Array pour les années d'expiration pour une nouvelle carte
      const d = new Date()
      const year = d.getFullYear()
      for (let index = 0; index < 7; index++) {
        const total = year + index
        const toString = total.toString()
        const sliceTotal = toString.slice(2, 4)
        this.cardExpiration.year.push(sliceTotal)
      }

      this.getData(false)
    },

    methods: {
      ...mapMutations([
        'resetNewCard',
      ]),
      getProvince (data) {
        if (data) {
          const findProvince = this.provincesPaysafe.find(item => item.value === data.state)
          if (findProvince) return findProvince.text
        }
        return null
      },
      async submit () {
        this.editForm = false
        // Start Spinner
        this.$loading.show()
        try {
          // =================================
          // Client dans la DB
          // =================================
          // Si il y a un id, le client est dans la DB. Sinon c'est un nouveau client
          if (this.submitForm.id) {
            await ClientsService.update(this.submitForm, this.submitForm.id)
          } else {
            await ClientsService.save(this.submitForm)
          }

          // Récupération des infos
          this.getData(false)
        } catch (error) {
          console.log(error)
          // Récupération des infos
          this.getData(false)
        }
      },
      async getData (notification) {
        // Start Spinner
        this.$loading.show()
        try {
          // Reset data
          this.submitForm = null
          this.cards = []

          this.submitForm = await ClientsService.query(this.userInfo.info.username, 'username')
          // console.log('client', this.submitForm)
          // Si l'utilisateur n'est pas dans la base de donnée, on prends les info de du client
          if (!this.submitForm) {
            // On affiche l'édition pour l'utilisateur
            this.editForm = true
            // Montre la confirmation
            this.$notifier.show({ message: this.$i18n.t('profile.snackBar.update_your_profile'), color: 'primary', icon: 'mdi-information', timeout: 5000, position: { top: true, center: true, left: false, right: false } })

            this.submitForm = {
              username: this.userInfo.info.username,
              prenom: this.userInfo.info.firstName,
              nom: this.userInfo.info.name,
              Contrats: null,
            }
          } else {
            const cards = await GlobalPaymentsCardService.list(this.submitForm.id)
            this.cards = cards.payment_methods.map(x => {
              // On enlève les x
              x.card.number_last4 = x.card.number_last4.slice(12)
              return x
            })
            this.updatePaiementsInscription()
          }

          if (notification) this.$notifier.show({ message: this.$i18n.t('profile.snackBar.profile_updated_successfully'), color: 'success', icon: 'mdi-checkbox-marked-circle', timeout: 5000, position: { top: true, center: true, left: false, right: false } })

          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
        try {
          // Stop Spinner
          this.$loading.hide()
          if (notification) this.$notifier.show({ message: this.$i18n.t('profile.snackBar.profile_updated_successfully'), color: 'success', icon: 'mdi-checkbox-marked-circle', timeout: 5000, position: { top: true, center: true, left: false, right: false } })
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      updatePaiementsInscription () {
        // On récupère les cartes relier au client
        // On regarde dans les cartes globalPayments si la carte est bien dans la table 'Inscription_paiement'
        for (const item of this.cards) {
          const updateCreate = {
            ClientId: this.submitForm.id,
            paiement_token: item.id,
            // nom_titulaire: `${this.userInfo.info.firstName} ${this.userInfo.info.name}`, // Avec GlobalPayement, on a pas le nom du titulaire. On mets les infos d'authentification
            dernier_chiffre: item.card.number_last4,
            mois_expiration: item.card.expiry_month,
            annee_expiration: item.card.expiry_year,
          }

          const findPaiementsInscriptionFind = this.submitForm.Inscription_paiements.find(x => x.paiement_token === item.id)

          // Si la carte n'est pas dans la table 'Inscription_paiement' on la mets sinon on la mets à jour
          if (findPaiementsInscriptionFind) {
            // Si il n'y a pas de nom de titulaire, on mets le nom du client. Avec GlobalPayement, on a pas le nom du titulaire. On mets les infos d'authentification
            if (!findPaiementsInscriptionFind.nom_titulaire) updateCreate.nom_titulaire = `${this.userInfo.info.firstName} ${this.userInfo.info.name}` // Avec GlobalPayement, on a pas le nom du titulaire. On mets les infos d'authentification
            PaiementsInscriptionService.update(updateCreate, findPaiementsInscriptionFind.id)
          } else {
            updateCreate.nom_titulaire = `${this.userInfo.info.firstName} ${this.userInfo.info.name}`
            PaiementsInscriptionService.save(updateCreate)
          }
        }

        // On supprime les cartes relier au client qui ne sont plus dans la table 'Inscription_paiement'
        for (const item of this.submitForm.Inscription_paiements) {
          const findPaiementsInscriptionFind = this.cards.find(x => x.id === item.paiement_token)
          if (!findPaiementsInscriptionFind) PaiementsInscriptionService.remove(item.id)
        }
      },
      async contractVerification (InscriptionPaiements) {
        try {
          // Si il i a un id de contrat, l'utilisateur à ajouter une nouvelle carte à un contrat dans la console
          if (this.userInfo.newCardContratId) {
            const contrat = await ContratsService.update({ InscriptionPaiementId: InscriptionPaiements.id }, this.userInfo.newCardContratId)
            // Swal
            Swal.fire({
              title: 'Attention !',
              // La carte de crédit finissant par ....0023 a été attribuée au contrat (221) 222-2222
              html: `${this.$i18n.t('profile.swal.text.card_association')} <b>....${InscriptionPaiements.dernier_chiffre}</b> ${this.$i18n.t('profile.swal.text.card_association1')} <b>(${contrat.no.slice(0, 3)}) ${contrat.no.slice(3, 6)}-${contrat.no.slice(6)}</b>`,
              icon: 'success',
            })

            // On efface le id du contrat pour l'ajout d'une nouvelle carte
            this.resetNewCard()
            // Retour au dashboard
            this.$router.push({ name: 'Dashboard' })
          } else {
            // Récupération des infos
            this.getData(false)
          }
        } catch (error) {
          console.log(error)
          // Stop Spinner
          // this.$loading.hide()
          // Récupération des infos
          this.getData(false)
        }
      },
      async cancel () {
        this.editForm = false
        // Start Spinner
        this.$loading.show()
        try {
          this.submitForm = await ClientsService.query(this.userInfo.info.username, 'username')
          // Si l'utilisateur n'est pas dans la base de donnée, on prends les info de oidc
          if (!this.submitForm) {
            this.submitForm = {
              username: this.userInfo.info.username,
              prenom: this.userInfo.info.firstName,
              nom: this.userInfo.info.name,
            }
          }
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      // ======================================
      // Carte de crédit
      // ======================================
      ifCardExpired (expiration) {
        const fullYear = `20${expiration.year}`
        const fullYearToNumber = parseInt(fullYear, 10)
        const d = new Date()
        const year = d.getFullYear()
        // Si l'année de la carte de crédit est plus grande que l'année c'est valide
        if (fullYearToNumber > year) return false
        // Si l'année de la carte de crédit est plus petite que l'année c'est invalide (On affichee l'îcone)
        if (fullYearToNumber < year) return true

        // Si l'année est égale, on vérifie le mois
        const month = d.getMonth() + 1
        // Si le mois est plus petit c'est invalide (On affiche l'îcone)
        if (parseInt(expiration.month, 10) < month) return true
        return false
      },
      createNewCard () {
        this.editCreateCardChange = true
        // Pas une édition
        this.editCardStatus = false
        // Reset card
        this.cardSelected = {
          reference: null,
          usage_mode: 'MULTIPLE',
          name: null,
          card: {
            number: null,
            expiry_month: null,
            expiry_year: null,
            cvv: null,
          },
        }

        // Show dialog
        this.showAddEditCardDialog = true
      },
      async submitNewCreditCard () {
        // Start Spinner
        this.$loading.show()
        try {
          // Si c'est une édition et que le client ne modifie pas sa carte, il modifie seulement son adresse de facturation, on ne fait pas de validation
          if (this.cardSelected.reference) {
            // On update la carte
            await GlobalPaymentsCardService.update(this.cardSelected, this.cardSelected.id, this.submitForm.id)
            // Récupération des infos
            this.getData(false)
          } else {
            // ==============================
            // Ajout de la carte
            // ==============================
            const info = await GlobalPaymentsCardService.save(this.cardSelected, this.submitForm.id)
            // Si le client à ajouter une carte de crédir dans le dashboard, on ajoute la carte au contrat
            this.contractVerification(info.inscriptionPaiement)
          }
          // On ferme le dialog
          this.showAddEditCardDialog = false
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async editCard (card) {
        // Par défaut le client ne change pas sa carte. Seulement son adresse de facturation
        this.editCreateCardChange = false
        // Une édition
        this.editCardStatus = true

        this.cardSelected = JSON.parse(JSON.stringify(card))
        this.cardSelected.card.number = `XXXXXXXXXXXX${this.cardSelected.card.number_last4}`
        // On ouvre le dialog
        this.showAddEditCardDialog = true
      },
      async deleteCard (card) {
        // Start Spinner
        this.$loading.show()
        try {
          // On regarde si la carte est relier à des contrats
          const inscriptionPaiement = await PaiementsInscriptionService.query(card.id, 'paiement_token')

          // Suppression du paiement token
          if (inscriptionPaiement) {
            // Si contrat, on regarde si les contrats sont tous actif, intégrité ou suspendu. Si il y a un contrat actif (1), intégrité (6) ou suspendu (7), le client ne peut pas supprimer sa carte car il y a un contrat. Le type est payable par carte de crédit
            if (inscriptionPaiement.Contrats.find(item => (item.statut === 1 || item.statut === 6 || item.statut === 7) && item.type === 2)) {
              // Swal
              Swal.fire(
                'Attention !',
                `${this.$i18n.t('profile.swal.text.cant_delete_card_contract')}`,
                'error',
              )

              // Stop Spinner
              this.$loading.hide()
              return
            }

            // Si il y a des commandes en cours, on ne peut pas supprimer une la carte
            if (inscriptionPaiement.Commandes.length) {
              // Swal
              Swal.fire(
                'Attention !',
                `${this.$i18n.t('profile.swal.text.cant_delete_card_order')}`,
                'error',
              )

              // Stop Spinner
              this.$loading.hide()
              return
            }
          }

          // Supression de la carte
          // On supprime l'inscription paiement si il y en a un
          if (inscriptionPaiement) await PaiementsInscriptionService.remove(inscriptionPaiement.id)
          // On supprime la carte
          await GlobalPaymentsCardService.remove(card.id, this.submitForm.id)

          // Récupération des infos
          this.getData(false)
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
