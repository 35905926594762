export const ClientEdit = {
    data () {
        return {
          types: [
            {
              item: this.$i18n.t('client.Employee').toUpperCase(),
              valeur: '1',
            },
            {
              item: this.$i18n.t('client.Retirement').toUpperCase(),
              valeur: '2',
            },
            {
              item: this.$i18n.t('client.Ex-employee').toUpperCase(),
              valeur: '3',
            },
            {
              item: this.$i18n.t('client.Special').toUpperCase(),
              valeur: '4',
            },
            {
              item: this.$i18n.t('client.Retired_teacher').toUpperCase(),
              valeur: '5',
            },
          ],
          sexes: ['M', 'F', 'N/A'],
          provinces: [
            this.$i18n.t('client.province.Alberta'),
            this.$i18n.t('client.province.British_Columbia'),
            this.$i18n.t('client.province.Prince_Edward_Island'),
            this.$i18n.t('client.province.Manitoba'),
            this.$i18n.t('client.province.New_Brunswick'),
            this.$i18n.t('client.province.Nova_Scotia'),
            this.$i18n.t('client.province.Nunavut'),
            this.$i18n.t('client.province.Ontario'),
            this.$i18n.t('client.province.Quebec'),
            this.$i18n.t('client.province.Saskatchewan'),
            this.$i18n.t('client.province.Newfoundland_and_Labrador'),
            this.$i18n.t('client.province.Northwest_Territories'),
            this.$i18n.t('client.province.Yukon'),
          ],
        }
    },

    computed: {
      regexPhoneNumber () {
        return /^([1-9]\d{2})(\d{3})(\d{4})$/
      },
      regexZipCode () {
        return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]\d[ABCEGHJ-NPRSTV-Z]\d$/i
      },
    },

    methods: {
      checkValidationClientInformation (client) {
          if (!client.ville || !client.telephone1 || !client.province || !client.prenom || !client.nom || !client.email || !client.code_postal || !client.adresse) {
            return true
          }
          return false
      },
    },
}
